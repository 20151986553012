@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap');

@tailwind base;

@layer base {
  body {
    @apply bg-app-black-secondary text-app-white-primary;
  }
  textarea {
    @apply bg-transparent;
  }
  /* Firefox */
  * {
    scrollbar-width: thin;
    scrollbar-color: #292a2a #131414;
  }

  /* Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 15px;
  }

  *::-webkit-scrollbar-track {
    background: #131414;
    border-radius: 5px;
  }

  *::-webkit-scrollbar-thumb {
    background-color: #292a2a;
    border-radius: 14px;
    border: 3px solid #545555;
  }
}

@tailwind components;

@layer components {
  .app-nav-container {
    @apply w-full max-w-7xl mx-auto p-4 sm:p-6;
  }
  .app-main-container {
    @apply w-full max-w-7xl mx-auto px-4 py-4 sm:py-8;
  }
  button {
    @apply active:scale-90 transition-all duration-200;
  }
  button:disabled {
    @apply active:scale-100;
  }
  .app-modal {
    width: min(90vw, 500px);
    max-height: 90vh;
  }
  .modal-open {
    overflow: hidden;
  }

  /* toggle button */
  .switch {
    position: relative;
    display: inline-block;
    height: calc(16px + 2px * 2);
    width: calc(16px * 2 + 2px * 2);
    min-width: calc(16px * 2 + 2px * 2);
  }
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  .switch input:checked + .slider {
    background-color: #333333;
  }
  .switch input:focus + .slider {
    box-shadow: 0 0 1px black;
  }
  .switch input:checked + .slider:before {
    -webkit-transform: translateX(16px);
    -ms-transform: translateX(16px);
    transform: translateX(16px);
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #cccccc;
    -webkit-transition: 0.15s;
    transition: 0.15s;

    border-radius: calc(16px + 2px * 2);
  }
  .slider:before {
    position: absolute;
    content: '';
    height: 16px;
    width: 16px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 50%;
  }
}

@tailwind utilities;

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}
